import { makeRequest } from "./utils";

////
// Creators
//
export const getCreators = (params) => makeRequest({
  method: "get",
  url: '/api/admin/creators',
  params,
});

export const getCreator = (creator_id) => makeRequest({
  method: "get",
  url: `/api/admin/creators/${creator_id}`,
});

export const updateCreatorWaitlist = ({creator_id, status}) => makeRequest({
  method: "put",
  url: `/api/admin/creators/${creator_id}/waitlist`,
  data: {status},
});

export const createCreatorRiskAssessment = (creator_id, assessment_data) => makeRequest({
  method: "post",
  url: `/api/admin/creator_risk_assessments`,
  data: {
    creator_risk_assessment: {
      creator_id: creator_id,
      note: assessment_data.note,
      risk_level: assessment_data.risk_level,
      reasons: assessment_data.reasons,
    }
  },
});
