
import React from 'react';
import {
  Card,
  Box,
  Text,
  Link,
  InlineStack,
  BlockStack,
} from '@shopify/polaris';

import { CardHeader, CardField } from '../../../../components/card';
import { formatDate, countryCodeToName } from '../../../../services/utils';
import { paths } from '../../../../constants';

export default function Profile({ creator }) {
  const { profile, email: loginEmail } = creator;
  const { location, birthday, shipping_address: shipping } = profile;
  const hasFullBirthday = birthday?.year && birthday?.month && birthday?.day;
  const hasShippingAddress = [
    shipping?.address_line_1,
    shipping?.postal_code,
    shipping?.city,
    shipping?.country,
  ].filter(Boolean).length > 0;

  return (
    <Card>
      <CardHeader title='Profile' />
      <Box paddingBlock="400">
        <CardField
          title={
            creator.type === "CommunityMember"
              ? "Applicant Email"
              : "Login Email"
          }
        >
          {loginEmail}
        </CardField>
        <CardField title='Contact Email'>
          {profile.email}
        </CardField>
        <CardField title='Manual Payouts Paypal Email'>
          {profile.manual_payouts_paypal_email || "-"}
        </CardField>
        <CardField title='Phone number (deprecated field)'>
          {profile.phone_number?.number || "-"}
        </CardField>
        <CardField title='Shipping phone number'>
          {shipping?.phone_number || "-"}
        </CardField>
        <CardField title='Display Name'>
          {profile.display_name}
        </CardField>
        <CardField title='First Name'>
          {profile.first_name}
        </CardField>
        <CardField title='Last Name'>
          {profile.last_name}
        </CardField>
        <CardField title='Gender'>
          {profile.gender}
        </CardField>
        <CardField title='Birthday'>
          {hasFullBirthday
            ? formatDate({
                value: new Date(
                  birthday.year,
                  birthday.month - 1,
                  birthday.day
                ),
                strftime: "%b %d, %Y",
              })
            : "-"}
        </CardField>
        <CardField title='Location'>
          {[
            countryCodeToName(location?.country),
            location?.state,
            location?.city,
          ]
            .filter(Boolean)
            .join(", ") || "-"}
        </CardField>

        <Box paddingBlockStart="300">
          <InlineStack gap="300" align="space-between">
            <Text as="p" fontWeight="strong" tone="subdued">
              Shipping Address
            </Text>
            {hasShippingAddress ? (
              <BlockStack>
                <Text variant="bodysm" as="p" tone="subdued">
                  {[shipping.address_line_1, shipping?.address_line_2]
                    .filter(Boolean)
                    .join(" - ")}
                </Text>
                <Text variant="bodysm" as="p" tone="subdued">
                  {`${shipping.postal_code}, ${shipping.city}`}
                </Text>
                <Text variant="bodysm" as="p" tone="subdued">
                  {[shipping?.state, countryCodeToName(shipping.country)]
                    .filter(Boolean)
                    .join(", ")}
                </Text>
              </BlockStack>
            ) : (
              <Text as="p" variant="bodyMd">
                -
              </Text>
            )}
          </InlineStack>
        </Box>

        <Box paddingBlockStart="300">
          <InlineStack gap="300" align="space-between">
            <Text as="p" fontWeight="strong" tone="subdued">
              Partners Account (clickable link)
            </Text>
            {profile.shopify_partner_id ? (
                <Link
                  target='_blank'
                  url={paths.external.shopifyPartners.replace(':partnersId' , profile.shopify_partner_id)}
                >
                  {profile.shopify_partner_id}
                </Link>
            ) : (
              <Text as="p" variant="bodyMd">
                -
              </Text>
            )}
          </InlineStack>
        </Box>

        <CardField title='Flipper ID'>
          <code>{creator.flipper_id}</code>
        </CardField>

        <CardField title='Blocked'>
          <code>{creator.blocked}</code>
        </CardField>
      </Box>
    </Card>
  );
}
