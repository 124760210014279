import baseURL from './services/api/baseURL';
import collabsBaseURL from './services/api/collabsBaseURL';

export const GENERIC_ERROR_MESSAGE =  "An error occurred. Please try again. If the error persists, please contact #collabs-devs slack channel.";

export const paths = {
  admin: {
    main: "/admin",
    merchants: "/admin/merchants",
    creators: "/admin/creators",
    products: "/admin/products",
    commissions: "/admin/commissions",
    feedback: "/admin/feedback",
    curatedCollections: "/admin/curated_collections",
  },
  merchants: {
    brand: "/admin/merchants/brands/:merchantId",
    agency: "/admin/merchants/agencies/:merchantId",
  },
  creator: {
    main: "/admin/creators/:creatorId",
    community: "/community/m/:token",
    login: `${collabsBaseURL}/employee/login_as/:uuid`,
  },
  curatedCollection: {
    main: "/admin/curated_collections/:collectionId",
  },
  commissions: {
    byCreator: "/admin/commissions/creator/:creatorId",
  },
  feedback: {
    main: "/admin/feedback",
  },
  logout: `${collabsBaseURL}/employee/logout`,
  home: "/",
  external: {
    sidekiqApi: `${baseURL}/internal/sidekiq/api`,
    sidekiqAnalytics: `${baseURL}/internal/sidekiq/analytics`,
    flipper: `${baseURL}/internal/flipper`,
    shopifyStore: "https://:store/admin/staff/login",
    shopifyPartners: "https://partners.shopify.com/internal/organizations/:partnersId",
    creatorGraphiQL: `${baseURL}/internal/graphiql/creator`,
    storeGraphiQL: `${baseURL}/internal/graphiql/merchant/:shopify_store_id`,
  },
};

export const images = {
  emptyStateImage: "https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png",
};
