import React, { useState, useCallback } from 'react';
import { ChoiceList, Card, InlineError, EmptyState, Modal, Select, Box, BlockStack, Form, FormLayout, TextField, Button, Badge, IndexTable } from '@shopify/polaris';
import { CardHeader } from '../../../../components/card';
import { createCreatorRiskAssessment } from '../../../../services/api/creators';
import { formatDate } from '../../../../services/utils';

const formatReason = (reason) => {
  return reason.split("_")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    .join(" ");
};

export default function RiskAssessments({ riskAssessments, creator, onRiskAssessmentCreated }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedReasons, setSelectedReasons] = useState([]);
  const [riskLevel, setRiskLevel] = useState('no_risk');
  const [note, setNote] = useState('');
  const [error, setError] = useState(null);

  const rows = riskAssessments.map((assessment, index) => {
    let formattedReasons = assessment.reasons
      .map(reason => {
        const formattedReason = formatReason(reason);
        return <Badge>{formattedReason}</Badge>;
      });

    const formattedDate = formatDate({
      value: new Date(assessment.created_at),
      strftime: "%b %d, %Y at %I:%M %p",
    })

    return (
      <IndexTable.Row position={index} key={index} >
        <IndexTable.Cell>{assessment.risk_level}</IndexTable.Cell>
        <IndexTable.Cell>{assessment.note}</IndexTable.Cell>
        <IndexTable.Cell><BlockStack gap="150" inlineAlign="start">{formattedReasons}</BlockStack></IndexTable.Cell>
        <IndexTable.Cell>{formattedDate}</IndexTable.Cell>
        <IndexTable.Cell>{assessment.assessed_by}</IndexTable.Cell>
      </IndexTable.Row>
    );
  });

  const handleCheckboxChange = useCallback((value) => setSelectedReasons(value), []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await createCreatorRiskAssessment(creator.id, {
        risk_level: riskLevel,
        note: note,
        reasons: selectedReasons,
      });

      if (response.error) {
        setError(response.error.response.data.errors.join(', '));
      } else {
        onRiskAssessmentCreated(response);
        setError(null);
        setRiskLevel('no_risk');
        setNote('');
        setSelectedReasons([]);
        setIsModalOpen(false);
      }
    } catch (error) {
      setIsModalOpen(false);
      setError(error.error.response.data.errors.join(', '));
    }
  };

  return (
    <Card title="Risk Assessments" actions={[{ content: 'Add Risk Assessment', onAction: () => setIsModalOpen(true) }]}>
      <CardHeader title="Risk Assessments" />
      <Box paddingBlock="400" overflowX="scroll">
      <BlockStack gap="100" inlineAlign="start">
        <IndexTable
          headings={[{title: 'Risk Level'}, {title: 'Notes'}, {title: 'Reasons'}, {title: 'Assessment Date'}, {title: 'Assessed By'}]}
          itemCount={riskAssessments.length}
          selectable={false}
          emptyState={
            <EmptyState
              heading="No risk assessments yet"
            />
          }
        >
          {rows}
        </IndexTable>

        <InlineError message={error} fieldID="errorID" />

        <Button primary onClick={() => setIsModalOpen(true)}>
          Create Risk Assessment
        </Button>
      </BlockStack>

      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Create Risk Assessment"
        primaryAction={{
          content: 'Submit',
          onAction: handleSubmit,
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: () => setIsModalOpen(false),
          },
        ]}
      >
        <Modal.Section>
          <Form onSubmit={handleSubmit}>
            <FormLayout>
              <Select
                label="Risk Level"
                options={[
                  {label: 'No Risk', value: 'no_risk'},
                  {label: 'Low', value: 'low'},
                  {label: 'High', value: 'high'}
                ]}
                onChange={setRiskLevel}
                value={riskLevel}
              />
              <TextField
                label="Notes"
                value={note}
                onChange={setNote}
                multiline={4}
              />
              <ChoiceList
                allowMultiple
                title="Reasons"
                choices={creator.possible_risk_assessment_reasons.map((reason) => ({
                  label: formatReason(reason),
                  value: reason,
                }))}
                selected={selectedReasons}
                onChange={handleCheckboxChange}
              />
            </FormLayout>
          </Form>
        </Modal.Section>
      </Modal>
      </Box>
    </Card>
  );
}
