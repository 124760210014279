import React from 'react';
import { createRoot } from 'react-dom/client';

import MerchantPage from './pages/merchants/merchant/Merchant';
import MerchantsTable from './pages/merchants/merchants_table/MerchantsTable';
import CreatorsTable from './pages/creators/creators_table/CreatorsTable';
import Creator from './pages/creators/creator/Creator';
import CuratedCollectionsList from './pages/curated_collections/curated_collections_list/CuratedCollectionsList';
import CuratedCollection from './pages/curated_collections/curated_collection/CuratedCollection';
import ProductsTable from './pages/products/ProductsTable';
import CommissionsTable from './pages/commissions/CommissionsTable';
import Feedback from './pages/feedback/Feedback';
import CreatorCommissionsTable from './pages/commissions/CreatorCommissionsTable';
import EmptyPage from './pages/empty/EmptyPage';

import { paths } from './constants';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import './styles/destyle.css';
import './styles/index.css';
import UserProvider from './context/UserContext';
import App from './App';

import { AppProvider } from "@shopify/polaris";
import '@shopify/polaris/build/esm/styles.css';

const root = createRoot(document.getElementById('root'));

const router = createBrowserRouter([
  {
    path: "/",
    element:  <App />,
    errorElement: <EmptyPage />,
    children: [
      {
        path: paths.home,
        element: <MerchantsTable /> ,
      },
      {
        path: paths.admin.main,
        element: <MerchantsTable /> ,
      },
      {
        path: paths.admin.merchants,
        element: <MerchantsTable /> ,
      },
      {
        path: paths.merchants.brand,
        element: <MerchantPage /> ,
      },
      {
        path: paths.merchants.agency,
        element: <MerchantPage /> ,
      },
      {
        path: paths.admin.creators,
        element: <CreatorsTable /> ,
      },
      {
        path: paths.creator.main,
        element: <Creator /> ,
      },
      {
        path: paths.admin.products,
        element: <ProductsTable /> ,
      },
      {
        path: paths.admin.commissions,
        element: <CommissionsTable /> ,
      },
      {
        path: paths.commissions.byCreator,
        element: <CreatorCommissionsTable /> ,
      },
      {
        path: paths.admin.curatedCollections,
        element: <CuratedCollectionsList /> ,
      },
      {
        path: paths.curatedCollection.main,
        element: <CuratedCollection /> ,
      },
      {
        path: paths.feedback.main,
        element: <Feedback /> ,
      },
      { path: "*", element: <EmptyPage /> },
    ],
  },
]);

root.render(
  <AppProvider>
    <React.StrictMode>
      <UserProvider>
        <RouterProvider router={router} />
      </UserProvider>
    </React.StrictMode>
  </AppProvider>
);
